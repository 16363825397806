import React, { Fragment, memo, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import formatDate from '@/utils/formatDate'
import { useSelector } from 'react-redux'
import Image from 'next/image'
import {
  firebaseGetErrorsBet,
  firebaseUpdateBet,
  getProfileSetting,
} from '@/firebase/utils'
import { errorMsg, successMsg } from '@/utils/toast'
import {
  getLeagueByName,
  getSportByName,
  getTeamByName,
} from '@/constants/countries-leagues'
import { sendPayment } from '@/services/relysia-queries'
import NextImage from '@/utils/Image'
import { NumberChip, renderNumber2 } from '@/utils/helper'
import { StatusChip } from './StatusChip'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { useRouter } from 'next/router'
import useExchangeRates from '@/hooks/useExchangeRates'
import moment from 'moment'
import authSelector from '@/redux/selectors/auth'

const calcSingleBet = (bet) => {
  let winPrice = 0

  const { betPrice, price } = bet || {
    betPrice: 0,
    price: 0,
  }

  if (Number(price) > 0) {
    winPrice = Number(betPrice) * (Number(price) / 100)
  } else {
    winPrice = Number(betPrice) * (100 / Math.abs(Number(price)))
  }

  return winPrice
}

const removeDollarSign = (amount) => {
  return amount.replace('$', '')
}

const SingleSlip = memo(
  ({ slipData, mainData, isMulti, topRounded, bottomRounded, slipBorder }) => {
    const matchDesc = slipData?.future
      ? slipData.future
      : `${slipData?.match?.home_team} vs ${slipData?.match?.away_team}`

    const sport = getSportByName(slipData?.match?.sport)
    const league = getLeagueByName(slipData?.match?.league)

    return (
      <div
        className={`bg-white/5 overflow-hidden ${
          topRounded ? 'rounded-t-xl' : ''
        } ${bottomRounded ? 'rounded-b-xl' : ''} ${
          slipBorder ? 'border-t-2 border-white/30 border-dotted' : ''
        }`}
      >
        <div className="bg-white/5 py-2 px-6 flex flex-row items-center justify-start gap-2 relative">
          <NextImage width={28} height={28} src={sport.logo} alt="sport logo" />
          <NextImage
            width={24}
            height={24}
            src={league.logo}
            alt="league logo"
          />

          <h4 className="overflow-ellipsis flex-1 text-left font-inter font-medium text-xs">
            {matchDesc}
          </h4>
        </div>
        <div className="px-6 py-4">
          <div className="flex flex-row flex-wrap items-center gap-3 relative">
            <div>
              <NextImage
                width={40}
                height={40}
                src={
                  slipData?.teamName && slipData?.teamName !== 'draw'
                    ? getTeamByName({
                        teamName: slipData?.teamName,
                        league: league.name,
                        sport: sport.name,
                      })?.logo
                    : league?.logo
                }
                alt="game name"
              />
            </div>
            <div className="flex-1">
              <div className="flex flex-row items-center space-x-2">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  {slipData?.market_name}
                </span>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  {!slipData?.teamName
                    ? slipData?.name
                    : slipData?.teamName && slipData?.teamName !== 'draw'
                    ? 'Winner'
                    : 'Draw'}
                </span>
                <div>
                  <NumberChip
                    number={renderNumber2(slipData?.price)}
                    className="bg-white/5 text-[#F7941D]"
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-between space-x-1 text-sm font-medium font-inter text-white relative">
                {!slipData?.teamName ? null : slipData?.teamName &&
                  slipData?.teamName !== 'draw' ? (
                  <div>
                    <span>
                      {slipData?.bet_points
                        ? slipData?.teamName +
                          ' ' +
                          '(' +
                          slipData?.bet_points +
                          ')'
                        : slipData?.teamName}
                    </span>
                  </div>
                ) : null}
                {slipData.grade && slipData.grade === 'win' ? (
                  <NextImage
                    width={24}
                    height={24}
                    className=""
                    src="/img/connected.png"
                    alt="connected"
                  />
                ) : slipData?.refunded ? (
                  <StatusChip
                    status={'Push'}
                    className="bg-[#F7941D]/20 text-[#F7941D]"
                  />
                ) : (
                  slipData.grade === 'loss' && (
                    <NextImage
                      width={34}
                      height={34}
                      className=""
                      src="/svgs/crossIcon.svg"
                      alt="crossIcon"
                    />
                  )
                )}
              </div>
            </div>
            {slipData?.match?.start_date ? (
              <div className="font-inter font-normal text-[0.60rem] absolute -right-3.5 -top-[0.65rem]">
                {'Start Time' +
                  ': ' +
                  formatDate(slipData?.match?.start_date, 'MM/DD/YYYY') +
                  ' ' +
                  formatDate(slipData?.match?.start_date, 'hh:mm A')}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  },
)
SingleSlip.displayName = 'SingleSlip'

export default function BetSlipsModel({
  isOpen,
  onClose,
  amount,
  data,
  onPressPrev,
  onPressNext,
  loader,
  nextBetIndex,
  prevBetIndex,
  isFailed = false,
  hideNavigation,
  isLive,
}) {
  const router = useRouter()
  const [touchStart, setTouchStart] = useState(null)
  const [paymentCompleted, setPaymentCompleted] = useState(false)

  const [touchEnd, setTouchEnd] = useState(null)
  const [value, setValue] = useState()
  const { user, isAuthenticated } = useSelector(authSelector)

  const { usdToBsv } = useExchangeRates()
  const [errorLogs, setErrorLogs] = useState([])
  const uid = data?.uid
  const [betPlacedUser, setBetPlacedUser] = useState({})
  const isMultiBet = data?.betSlips ? true : false

  const totalBet = (
    Number(isMultiBet ? data?.price : data?.betPrice) || 0.0
  )?.toFixed(7)
  const totalPayout = (
    Number(isMultiBet ? data?.possibleWinPrice : calcSingleBet(data)) || 0.0
  )?.toFixed(7)

  const countTotalPayout = (totalBet, totalPayout) => {
    const sum = Number(totalBet) + Number(totalPayout)
    return sum.toFixed(7)
  }

  useEffect(() => {
    setValue(countTotalPayout(totalBet, totalPayout))
  }, [totalBet, totalPayout])

  useEffect(() => {
    getProfile(uid)
  }, [uid])
  const getErrorLogs = async (id) => {
    try {
      const errorLogsData = await firebaseGetErrorsBet(id)
      setErrorLogs(errorLogsData)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (isFailed && data) {
      getErrorLogs(data?.betID)
    }
  }, [isFailed, data])
  const keyListener = React.useCallback(
    (event) => {
      if (event.key === 'ArrowRight') {
        if (onPressNext && nextBetIndex > -1) {
          onPressNext(nextBetIndex)
        }
      } else if (event.key === 'ArrowLeft') {
        if (onPressPrev && prevBetIndex > -1) {
          onPressPrev(prevBetIndex)
        }
      }
    },
    [nextBetIndex, prevBetIndex],
  )

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener('keydown', keyListener, false)
      return () => {
        document.removeEventListener('keydown', keyListener, false)
      }
    }
  }, [keyListener])

  const minSwipeDistance = 50

  const onTouchStart = (e) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      if (onPressPrev && prevBetIndex > -1) {
        onPressPrev(prevBetIndex)
      }
    } else if (isRightSwipe) {
      if (onPressNext && nextBetIndex > -1) {
        onPressNext(nextBetIndex)
      }
    }
  }

  async function getProfile(uid) {
    const res = await getProfileSetting(uid)

    if (res) {
      setBetPlacedUser(res)
    }
  }

  const moveToBetLink = (txId) => {
    window.open(`https://whatsonchain.com/tx/${txId}`, '_blank')
  }

  const totalOdds = isMultiBet
    ? data?.betSlips?.reduce((acc, curr) => (acc += curr?.price), 0)
    : data?.price

  const bsvVal = Number(usdToBsv(totalPayout))?.toFixed(9)

  if (!data) return null

  const onChangeHandler = (e) => {
    const inputValue = e.target.value
    // Ensure the input only contains numeric characters
    if (/^\d*$/.test(inputValue)) {
      setValue(inputValue)
    }
  }

  const handleSubmit = async () => {
    const valueToCompare = Number(totalBet) + Number(totalPayout)
    // totalBet > totalPayout ? Number(totalBet) : Number(totalPayout)
    if (!betPlacedUser?.uid) {
      toast.error('betplaced user not found')
      return
    } else if (betPlacedUser.uid && !betPlacedUser.wallet) {
      toast.error('invalid user')
      return
    } else if (value > valueToCompare) {
      toast.error(`bet value should be smaller than ${valueToCompare}`)

      return
    }
    setPaymentCompleted(true)
    const bsvVal = Number(usdToBsv(value)?.toFixed(9))
    const dataArray = [
      {
        to: betPlacedUser.wallet.paymail,
        amount: bsvVal,
        notes:
          data?.grade === 'refund' || data?.grade === 'push'
            ? `your bet is refunded: ${data?.betID}`
            : 'Congratulations! You have won.',
      },
    ]
    try {
      const txID = await sendPayment(dataArray)
      if (txID) {
        await firebaseUpdateBet({
          id: data.betID,
          data: { ...data, winTxID: txID, status: 'completed' },
        })
        successMsg('Operation Completed Sucessfully...')
        setPaymentCompleted(false)
        setValue('')
        return txID
      } else {
        throw errorMsg('Transaction Failed...')
      }
    } catch (error) {
      setPaymentCompleted(false)
      setValue('')
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40 bg-white "
        onClose={onClose}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`fixed inset-0 backdrop-blur-md bg-black/90`} />
        </Transition.Child>
        <div className="fixed inset-0">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="flex flex-row items-center justify-center">
                <Dialog.Panel
                  className={
                    'transform transition-all shadow-2xl w-full relative'
                  }
                >
                  <div
                    className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer z-30"
                    onClick={onClose}
                  >
                    <NextImage
                      width={20}
                      height={20}
                      src="/svgs/x-close.svg"
                      alt="close button"
                    />
                  </div>
                  <div className="absolute top-[50%] right-[11px]">
                    {!hideNavigation && (
                      <div className="flex items-center justify-center w-[40px] h-[40px] bg-white/10 rounded-full cursor-pointer relative">
                        {loader ? (
                          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5"></div>
                        ) : (
                          <NextImage
                            fill
                            src="/svgs/arrow-right.svg"
                            onClick={() => {
                              if (onPressNext && nextBetIndex > -1) {
                                onPressNext(nextBetIndex)
                              }
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="absolute top-[50%] left-[11px]">
                    {!hideNavigation && (
                      <div className="flex items-center justify-center w-[40px] h-[40px] bg-white/10 rounded-full cursor-pointer relative">
                        <NextImage
                          fill
                          src="/svgs/arrow-left.svg"
                          onClick={() => onPressPrev(prevBetIndex)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="rounded-b-xl bg-white/5 pt-6 px-6 overflow-auto scroller max-h-[calc(100vh-200px)] min-w-[23rem] md:min-w-[28rem]  ">
                    {/* Info */}
                    <div className="space-y-3 flex flex-col items-center">
                      <div className="flex flex-row items-center justify-center text-base font-semibold font-inter">
                        <h4>{'Sports' + ' ID' + ' ' + data?.betID}</h4>
                        <div className="flex flex-row items-center gap-2 ml-2 relative w-14 justify-around">
                          <div>
                            <NextImage
                              width={30}
                              height={30}
                              src="/svgs/copy-l.svg"
                              className="cursor-pointer"
                              onClick={() => {
                                window.navigator.clipboard.writeText(
                                  data?.betID,
                                )
                                successMsg(
                                  `BetID ${data?.betID} Successfully Copied`,
                                )
                              }}
                            />
                          </div>
                          <div>
                            <NextImage
                              width={30}
                              height={30}
                              src="/svgs/link.svg"
                              className="cursor-pointer"
                              onClick={() => {
                                window.navigator.clipboard.writeText(
                                  `${window.location.host}/embed?uid=${
                                    data['uid'] ? data['uid'] : user['uid']
                                  }&betId=${data.betID}`,
                                )
                                successMsg(`Bet slip link successfully copied`)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-[#98A2B3] font-inter space-x-2 flex flex-row items-center justify-center">
                        <h4 className="font-normal text-base">Placed by</h4>
                        <div className="bg-white/5 rounded-2xl px-2.5 py-1 flex flex-row items-center gap-1 relative">
                          <NextImage
                            width={15}
                            height={15}
                            src="/svgs/avatar-mini.svg"
                          />
                          {betPlacedUser.profileStatus === 'public' ||
                          (betPlacedUser.profileStatus === 'private' &&
                            user?.uid === data?.uid) ||
                          user?.roleType === 'admin' ? (
                            <h4 className="font-medium text-sm capitalize">
                              {betPlacedUser?.username ||
                                betPlacedUser?.displayName}
                            </h4>
                          ) : (
                            <h4 className="font-medium text-sm capitalize">
                              {'Hidden'}
                            </h4>
                          )}
                        </div>
                        {isLive && (
                          <div className="bg-blue-300/30 rounded-2xl px-2.5 py-1 flex flex-row items-center gap-1 relative">
                            <p className="text-blue-500 font-bold text-xs">
                              Live Bet
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="font-inter font-normal text-xs">
                        {'on' +
                          ' ' +
                          formatDate(data?.placedAt, 'MM/DD/YYYY') +
                          ' ' +
                          formatDate(data?.placedAt, 'hh:mm A')}
                      </div>
                      {(user && user.uid === data?.uid) ||
                      user?.roleType === 'admin' ? (
                        <div className="text-[#98A2B3] font-inter space-x-2 flex flex-row items-center justify-center">
                          <h4 className="font-normal text-base">Bet Notes:</h4>
                          <div className="bg-white/5 rounded-2xl px-2.5 py-1 flex flex-row items-center flex-wrap gap-1">
                            <h4 className="font-medium text-sm">
                              {(data?.note && user?.uid === data?.uid) ||
                              (data?.note && user?.roleType === 'admin') ? (
                                <div>
                                  <p>{data?.note}</p>
                                </div>
                              ) : (
                                <h1>No Bet Notes</h1>
                              )}
                            </h4>
                          </div>
                        </div>
                      ) : null}
                      {/* <div className="font-inter font-normal text-xs">
                        {'Start Time' +
                          ': ' +
                          formatDate(data?.placedAt, 'MM/DD/YYYY') +
                          ' ' +
                          formatDate(data?.placedAt, 'hh:mm A')}
                      </div> */}
                    </div>
                    {/* Slips */}
                    <div className="mt-6 ml-[2rem] w-[16rem] sm:w-[21rem] mb-2">
                      {isMultiBet ? (
                        <div>
                          {data?.betSlips?.map((slip, index) => {
                            return (
                              <SingleSlip
                                key={slip.betID}
                                slipData={slip}
                                mainData={data}
                                topRounded={!index}
                                slipBorder={
                                  data?.betSlips?.length === index + 1 && index
                                }
                                bottomRounded={
                                  data?.betSlips?.length === index + 1
                                }
                                isMulti={true}
                              />
                            )
                          })}
                        </div>
                      ) : (
                        <div>
                          <SingleSlip
                            slipData={data}
                            mainData={data}
                            topRounded
                            bottomRounded
                            slipBorder={false}
                            isMulti={false}
                          />
                        </div>
                      )}
                    </div>
                    {isFailed && Boolean(errorLogs?.length) && (
                      <div className="space-y-5 mt-5 max-w-[23rem] md:max-w-[28rem]">
                        <h1 className=""> Error logs</h1>
                        <div className="w-full  space-y-2  justify-center max-h-[160px] overflow-y-auto">
                          {errorLogs
                            .sort(function (a, b) {
                              return moment.utc(b.time).diff(moment.utc(a.time))
                            })
                            .map((data, index) => (
                              <div
                                key={data.time}
                                className={`${
                                  index % 2 === 0
                                    ? 'bg-white/5'
                                    : 'bg-transparent'
                                }  w-full`}
                              >
                                <p
                                  className={` w-full break-words text-start text-xs p-1`}
                                >
                                  Date: {moment(data.time).format('lll')}
                                </p>
                                <p
                                  className={`w-full break-words text-start text-xs p-1`}
                                >
                                  {data.error}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                    {/* Need also one more condition to check either the payout was failed  */}
                    {user &&
                      user.roleType &&
                      user.roleType === 'admin' &&
                      betPlacedUser.uid &&
                      betPlacedUser.wallet &&
                      betPlacedUser.wallet.paymail &&
                      router.pathname.includes('admin/bets') && (
                        <div className="mt-4 ml-[2rem] w-[16rem] sm:w-[21rem]">
                          <Formik
                            initialValues={{
                              payout: '',
                            }}
                            onSubmit={handleSubmit}
                          >
                            {({ handleSubmit }) => {
                              return (
                                <>
                                  {(data?.grade === 'refund' ||
                                    data?.grade === 'win' ||
                                    data?.grade === 'push') && (
                                    <div className="flex items-center flex-row gap-x-2 sm:w-[360px]">
                                      <label
                                        for="payout"
                                        className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                      >
                                        Payout
                                      </label>
                                      <div className="flex flex-1">
                                        <span className="inline-flex items-center px-2 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                          <Image
                                            src={'/svgs/dollar-circle.svg'}
                                            width={25}
                                            height={25}
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <input
                                          id="payout"
                                          className="rounded-none rounded-r-lg bg-[#0D0F13] border-[#26272B] outline-none focus:outline-none focus:border-[#26272B] form-control focus:border-2 sm:text-sm border-2 h-[42px] p-2 block flex-1 min-w-0 w-full text-sm"
                                          onChange={onChangeHandler}
                                          type="number"
                                          placeholder="Enter Payout"
                                          autoComplete="off"
                                          label="payout"
                                          name="payout"
                                          value={value}
                                        />
                                      </div>

                                      <button
                                        type="button"
                                        disabled={
                                          Number(value) === 0 ||
                                          paymentCompleted
                                        }
                                        className="items-center disabled:bg-white/5 disabled:hover:bg-white/5 bg-[#008000]  flex justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-white hover:bg-[#008000]"
                                        onClick={handleSubmit}
                                      >
                                        Send Manual
                                      </button>
                                    </div>
                                  )}
                                </>
                              )
                            }}
                          </Formik>
                        </div>
                      )}

                    {/* Stats */}
                    <div className="mt-8">
                      <div className="flex items-center justify-center relative">
                        <NextImage
                          width={104}
                          height={32}
                          src="/img/LILBIT LOGO-full.webp"
                          className="w-[6.5rem] h-8"
                        />
                      </div>
                      <div className="mt-3 mb-10 space-y-4 font-inter text-xs">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="font-semibold">Total Odds</h3>
                          <h4 className="font-medium">
                            {renderNumber2(totalOdds)}
                          </h4>
                        </div>
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="font-semibold">Total Bet</h3>
                          {data?.betTxId ? (
                            <h4
                              className="font-medium hover:cursor-pointer hover:underline"
                              onClick={() => moveToBetLink(data?.betTxId)}
                            >
                              ${totalBet}
                            </h4>
                          ) : (
                            <h4 className="font-medium">${totalBet}</h4>
                          )}
                        </div>
                        {data?.refunded ? (
                          <div className="flex flex-row items-center justify-between">
                            <h3 className="font-semibold">Refund</h3>
                            {data?.refundedTxId ? (
                              <h4
                                className="font-medium hover:cursor-pointer hover:underline"
                                onClick={() =>
                                  moveToBetLink(data?.refundedTxId)
                                }
                              >
                                ${totalBet}
                              </h4>
                            ) : (
                              <h4 className="font-medium">${totalBet}</h4>
                            )}
                          </div>
                        ) : (
                          <>
                            <div className="flex flex-row items-center justify-between">
                              <h3 className="font-semibold">Est. Payout</h3>
                              {data?.winTxId ? (
                                <h4
                                  className="font-medium hover:cursor-pointer hover:underline"
                                  onClick={() => moveToBetLink(data?.winTxId)}
                                >
                                  $
                                  {amount
                                    ? removeDollarSign(amount)
                                    : totalPayout}
                                </h4>
                              ) : (
                                <h4 className="font-medium">${totalPayout}</h4>
                              )}
                            </div>
                            {user?.roleType === 'admin' && (
                              <div className="flex flex-row items-center justify-between">
                                <h3 className="font-semibold">
                                  Est. Payout Bsv
                                </h3>
                                {data?.winTxId ? (
                                  <h4
                                    className="font-medium hover:cursor-pointer hover:underline"
                                    onClick={() => moveToBetLink(data?.winTxId)}
                                  >
                                    {bsvVal}
                                  </h4>
                                ) : (
                                  <h4 className="font-medium">{bsvVal}</h4>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
        +{' '}
      </Dialog>
    </Transition>
  )
}
