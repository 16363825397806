import sportsSelector from '@/redux/selectors/sports'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

export default function useExchangeRates() {
  const { exchangeRates } = useSelector(sportsSelector)

  const usdToBsv = useCallback(
    (price) => {
      if (!exchangeRates) return price
      const bsv = price ? price / (100000000 * exchangeRates) : 0.0
      return Number(bsv)
    },
    [exchangeRates],
  )

  const bsvToUsd = useCallback(
    (price) => {
      if (!exchangeRates) return price
      const bsv = price ? price * (100000000 * exchangeRates) : 0.0
      return Number(bsv)
    },
    [exchangeRates],
  )

  return {
    usdToBsv,
    bsvToUsd,
  }
}
