import PropTypes from 'prop-types'

const FormInput = ({
  label,
  className,
  mainClass,
  labelClass,
  icon: Icon,
  required,
  isRight,
  showBorder = true,
  background,
  ...props
}) => {
  const { id } = props
  return (
    <div className={`${mainClass}`}>
      {label && (
        <label
          htmlFor={id}
          className={`block text-sm font-medium mb-1.5 ${labelClass}`}
        >
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      )}

      <div
        className={`flex flex-row items-center rounded-lg p-2 ${
          background ? 'bg-[#ffffff1a]' : 'bg-white/5'
        }  ${showBorder && 'border border-white/20'} ${className} `}
      >
        {Icon && !isRight && (
          <div className={`flex items-center cursor-pointer relative`}>
            <Icon className="w-5 h-5 text-white/20" alt="icon" />
          </div>
        )}
        <input
          id={id}
          {...props}
          required={required}
          className={`
           appearance-none border-0 p-0 py-[1px] block w-full pl-2 pr-2 text-base bg-transparent font-normal shadow-sm placeholder:text-[#6B7280] focus:outline-none`}
        />
        {Icon && isRight && (
          <div className={`flex items-center cursor-pointer`}>
            <Icon className="w-5 h-5 text-white/20" alt="icon" />
          </div>
        )}
      </div>
    </div>
  )
}

FormInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.elementType,
  required: PropTypes.bool,
  className: PropTypes.string,
  mainClass: PropTypes.string,
  labelClass: PropTypes.string,
  background: PropTypes.bool,
}

export default FormInput
